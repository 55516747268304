<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <div class="card-header">
          <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                <i class="fas fa-home"></i>
                Ajouter un etudiant
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body p-4">
          <div class="tab-content">
            <div class="tab-pane active" id="personalDetails" role="tabpanel">
              <form @submit.prevent="initAdmition">
                <div class="row">
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="student" class="form-label">Etudiant</label>
                      <Multiselect class="form-control" v-model="data.student"
                        :class="{ 'is-invalid': v$.data.student.$error && data.student || v$.data.student.$error && submited, }"
                        :close-on-select="true" :searchable="true" :show-labels="false" :options="studentOptions" />
                      <div v-for="(item, index) in v$.data.student.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.student || v$.data.student.$error && submited">{{ item.$message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="faculty" class="form-label">Filière</label>
                      <Multiselect class="form-control" v-model="data.faculty"
                        :class="{ 'is-invalid': v$.data.faculty.$error && data.faculty || v$.data.faculty.$error && submited, }"
                        :close-on-select="true" :searchable="true" :show-labels="false" :options="facultiesOptions" />
                      <div v-for="(item, index) in v$.data.faculty.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.faculty || v$.data.faculty.$error && submited">{{ item.$message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="hstack gap-2 justify-content-end">
                      <button type="submit" class="btn btn-primary">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {},
      admitData: {},
      students: {},
      faculties: {},
      studentOptions: [],
      facultiesOptions: [],
      submited: false,

      title: "Nouvel Etudiant",
      items: [
        {
          text: "Etudiants",
          href: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      student: {
        required: helpers.withMessage("Veuillez selectionnez un étudiant", required),
      },
      faculty: {
        required: helpers.withMessage("Veuillez selectionnez une filière", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getAllStudents();
    this.getAllFileres();
  },

  methods: {
    initAdmition() {
      this.submited = true;
      this.v$.data.$touch();
      this.admitStudent();
    },
    getAllStudents() {
      const self = this;
      this.$store.dispatch('getRequest', { route: 'api/user/etudiant ', data: this.data })
        .then(function (response) {
          response.data.searchedUsers.forEach(element => {

            self.studentOptions.push({ value: element.id, label: element.nom + ' ' + element.prenom });

          });
        },
          function (error) {
            console.log(error);
          }
        )
    },

    getAllFileres() {
      const self = this;
      this.$store.dispatch('getRequest', { route: 'api/departments', data: this.data })
        .then(
          function (response) {
            response.data.filieres.forEach(element => {

              self.facultiesOptions.push({ value: element.id, label: element.code_filiere });

            });
          },
          function (error) {
            console.log(error);
          }
        )
    },
    admitStudent() {
      this.admitData = {
        etudiant_id: this.data.student,
        filiere_id: this.data.faculty,
        statut: 'actif',
        annee_scolaire: '2021-2022',
      },
        this.$store.dispatch('postRequest', { route: '/api/inscrir', data: this.admitData })
          .then(
            function (response) {
              Swal.fire(response.data.message, "Etudiant Inscrit!", "success");
            },
            function (error) {
              console.log(error);
              Swal.fire({
                title: "Oops...",
                text: "Il y a eu une erreur!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          )
    },
  },
};
</script>

